.footer_service_description_main{
    margin-top: 25px;
}
.footer_services_description{
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.footer_services_description{
    .fas{
        font-size: 10px;
    }
}

.footer_services_description p{
    margin-left: 10px;
    letter-spacing: 0.5px;
}