.class_form{
    padding-top: 40px;
    padding-bottom: 40px;
}

.class_form_input_textarea{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.class_form h1{
    color:black;
    text-align: center;
    font-weight: bolder;
    font-size: 40px;
    font-style: normal;
    padding-top: 60px;
    text-transform: uppercase;
}

form{

    margin-top: 20px;
}

.class_form_input{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
}

input{
    outline:none;
    padding: 14px;
    margin: 8px;
    border: 1px solid #E2E6E7;
    width: 550px;
    background-color: #f6f6f6;
    
}

input::placeholder{
    opacity: 0.4;
}

textarea{
    border: 1px solid #E2E6E7;
    background: white;
    height: 230px;
    outline: 0;
    resize: none;
    padding: 14px;
    width: 500px;
    background-color: #f6f6f6;
}

textarea::placeholder{
    opacity: 0.4;
}

.mon_bouton_form{
    display: flex;
    justify-content: center;
    align-items: center;
}

.mon_bouton_form button{
    padding: 18px 40px;
    color: white;
    background: black;
    border:none;
    margin-top: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iframe{
    margin-bottom: -10px;
}

.error_contact{
    color:red;
    width: 100%;
    font-size: 14px;
    font-style: italic;
    margin-right: 40px;
}

.mail_envoye{
    text-align: center;
    padding-top: 20px;
    color: green;
}

@media screen and (max-width: 768px) {
    .class_form{
        padding-top: 0px;
    }
    .class_form_input_textarea{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .class_form_input{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;
    }
    input{
        width: 100%;
    }
    textarea{
        width: 80%;
        margin-top: 20px;

    }
    .error_contact{
        margin-left: 50px;
    }
    .class_form h1{
        text-transform:capitalize;
        font-size: 25px;
    }
}