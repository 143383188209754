.galerie_img{
    width: 1276px;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    >.galerie_img_inside{
        margin: 20px;
        transition: all .5s ease-in-out;
        >img{
            width: 300px;
            height: 300px;
            cursor: pointer;
            border: 1px solid black;
            padding: 10px;
            transition: all .3s ease-in-out;
            object-fit: cover;

            &:hover{
                transform: scale(1.05);
                box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
            }
        }

    }

}

.titre_galerie{
    color: black;
    text-align: center;
    font-weight: bolder;
    font-size: 40px;
    font-style: normal;
    padding-top: 100px;
    text-transform: uppercase;
}

@media only screen and (max-width: 320px){ // petit smartphone
    .galerie_img{
        width: 1276px;
        margin: auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    
        >.galerie_img_inside{
            margin: 20px;
            transition: all .5s ease-in-out;
            >img{
                width: 300px;
                height: 300px;
                cursor: pointer;
                border: 1px solid black;
                padding: 10px;
                transition: all .3s ease-in-out;
    
                &:hover{
                    transform: scale(1.05);
                    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
                }
            }
    
        }
    
    }
}

@media screen and (min-width: 321px) and (max-width: 767px) {
    .galerie_img{
        width: 80%;
        margin: auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    
        >.galerie_img_inside{
            margin: 20px;
            transition: all .5s ease-in-out;
            >img{
                width: 100px;
                height: 100px;
                cursor: pointer;
                border: 1px solid black;
                padding: 5px;
                transition: all .3s ease-in-out;
    
                &:hover{
                    transform: scale(1.05);
                    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
                }
            }
    
        }
    
    }
    .titre_galerie{
        padding-top: 50px;
        font-size: 25px;
        text-transform: capitalize;
    }
    .fslightbox-fade-in-strong {
        >.fslightboxs {
            width: 200px;
            height: 200px;
        }

    }
    

}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .galerie_img{
        width: 80%;
        margin: auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    
        >.galerie_img_inside{
            margin: 20px;
            transition: all .5s ease-in-out;
            >img{
                width: 100px;
                height: 100px;
                cursor: pointer;
                border: 1px solid black;
                padding: 5px;
                transition: all .3s ease-in-out;
    
                &:hover{
                    transform: scale(1.05);
                    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
                }
            }
    
        }
    
    }

}