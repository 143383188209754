.services{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
   
}

.serviceH1{
    //color:#f36639;
    color:black;
    font-size: 40px;
    text-transform: uppercase;
    text-align: center;
    font-style: normal;
    font-weight: 900;
    padding-top: 100px;
}
.service_image_texte{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 3.5rem 3.5rem;
}

.service_image_texte img{
    width: 65px;
    height: 65px;
}   

.service_image_texte p{
    color:black;
    text-transform: uppercase;
    text-align: center;
    margin-top: 15px;
}

@media screen and (max-width: 768px) {
    .service_image_texte{
        padding: 30px;
    }
    .service_image_texte p{
        font-size: 12px;
    }
    .serviceH1{
        padding-top: 50px;
        font-size: 25px;
        text-transform:capitalize;
    }
    .services{
        padding-top: 30px;
    }
    .service_image_texte img{
        width: 90px;
        height: 90px;
    }  
}